import React from 'react';
import Navbar from '../../Navbar';
import './style.scss';
import CTAButtons from '../../CTAButtons';
import Slide from 'react-reveal/Slide';

const Header = () => {
    return (
        <div className='oneOneOneHeaderWrap'>
            <div className='engageContainer'>
                <Navbar />

                <div className='oneOneOneContentWrap'>
                    <Slide top>
                        <div className='oneOneOneContentLeft'>
                            <div className='coming-soon'>Coming Soon</div>
                            <h1>
                                Better insights.
                                <br />
                                Fewer meetings.
                            </h1>
                            <p>
                                Send an instant and personal 1v1 Pulse. Get thoughtful and timely feedback on what your
                                people think or feel. All inside Slack and Microsoft Teams.
                            </p>
                            <CTAButtons />
                        </div>
                    </Slide>
                    <Slide right>
                        <div className='oneOneOneContentRight'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/one-on-one/illustration.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/illustration.svg'}
                                alt=''
                            />
                        </div>
                    </Slide>
                </div>
            </div>
        </div>
    );
};

export default Header;
