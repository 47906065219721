import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../components/engagewith/OneOnOneMeeting/Header';
import FastFunSimple from '../../../components/engagewith/OneOnOneMeeting/FastFunSimple';
import Actionable from '../../../components/engagewith/OneOnOneMeeting/Actionable';
import Footer from '../../../components/engagewith/Footer';
import './styles.scss';
import GetStarted from '../../../components/engagewith/OneOnOneMeeting/GetStarted';

const Lander = () => {
    return (
        <div>
            <Helmet>
                <title>One-on-One Feedback inside Slack & MS Teams | EngageWith</title>
                <meta property='og:title' content='One-on-One Feedback inside Slack & MS Teams | EngageWith' />
                <meta property='og:site_name' content='EngageWith' />
                <meta property='og:url' content='https://www.springworks.in/engagewith/one-on-one-meeting/' />
                <meta
                    property='og:description'
                    content='Get one-on-one meeting feedback with instant, personalised & customisable Pulses. Take regular feedback inside Slack & Microsoft Teams with deeper insights'
                />
                <meta
                    name='description'
                    content='Get one-on-one meeting feedback with instant, personalised & customisable Pulses. Take regular feedback inside Slack & Microsoft Teams with deeper insights'
                />
                <meta property='og:type' content='website' />
                <meta
                    property='og:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/engage_header_logo.png'
                />
                <link rel='canonical' href='https://www.springworks.in/engagewith/one-on-one-meeting/' />
                <meta name='twitter:card' content='summary' />
                <meta name='twitter:site' content='@springroleinc' />
                <meta name='twitter:title' content='One-on-One Feedback inside Slack & MS Teams | EngageWith' />
                <meta
                    name='twitter:description'
                    content='Get one-on-one meeting feedback with instant, personalised & customisable Pulses. Take regular feedback inside Slack & Microsoft Teams with deeper insights'
                />
                <meta
                    name='twitter:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/engage_header_logo.png'
                />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='600' />
                <script type='application/ld+json'>
                    {`{
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "EngageWith",
                        "url": "https://www.springworks.in/engagewith/",
                        "logo": "https://d1xyh4hv0scrr0.cloudfront.net/web/engage_logo.jpg",
                        "sameAs": [
                          "https://www.facebook.com/springrole",
                          "https://twitter.com/springroleinc/",
                          "https://www.linkedin.com/organization-guest/company/springrole/",
                          "https://www.youtube.com/channel/UC7jYeZWJHhsV_2266gVMsZw"
                        ]
                    }`}
                </script>
            </Helmet>
            <Header />
            <GetStarted />
            <FastFunSimple />
            <Actionable />
            <Footer />
        </div>
    );
};

export default Lander;
