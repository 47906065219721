import React from 'react';
import './style.scss';
import CTAButtons from '../../CTAButtons';

const Actionable = () => {
    return (
        <div className='actionableWrap'>
            <div className='triviaContainer'>
                <div className='actionableInnerWrap'>
                    <div className='actionableHeader'>
                        <h3>Get actionable insights with 1v1 meeting</h3>
                        <CTAButtons />
                    </div>

                    <div className='actionableIllustration'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/getIllustration.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/getIllustration.svg'}
                            alt='illustration'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Actionable;
