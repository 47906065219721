import React from 'react';
import './style.scss';

const GetStarted = () => {
    return (
        <div className='oneOnOneGetStartedWrap'>
            <div className='triviaContainer'>
                <div className='getStartedHeader'>
                    <h2>Three simple steps to get started</h2>
                </div>

                <div className='ptpGetStartedSteps'>
                    <div className='ptpGetStartedContentImg'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepOne.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepOne.svg'}
                            alt=''
                        />
                    </div>
                    <div className='ptpGetStartedContent'>
                        <h4>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepSend.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepSend.svg'}
                                alt=''
                            />
                            Step&nbsp;1
                        </h4>
                        <h3>Send a Pulse</h3>
                        <div className='ptpGetStartedContentImg'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepOne.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepOne.svg'}
                                alt=''
                            />
                        </div>
                        <p>Because, 1v1 meetings don’t have to be in real-time, at least not always.</p>
                    </div>
                </div>

                <div className='ptpGetStartedSteps'>
                    <div className='ptpGetStartedContentImg'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepTwo.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepTwo.svg'}
                            alt=''
                        />
                    </div>
                    <div className='ptpGetStartedContent'>
                        <h4>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepSync.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepSync.svg'}
                                alt=''
                            />
                            Step 2
                        </h4>
                        <h3>Sync-up with who you want to</h3>
                        <div className='ptpGetStartedContentImg'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepTwo.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepTwo.svg'}
                                alt=''
                            />
                        </div>
                        <p>
                            Catch-up with your peers, attendees, managers anytime, anywhere by encouraging them to
                            participate and answer your Pulse.
                        </p>
                    </div>
                </div>

                <div className='ptpGetStartedSteps'>
                    <div className='ptpGetStartedContentImg'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepThree.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepThree.svg'}
                            alt=''
                        />
                    </div>
                    <div className='ptpGetStartedContent'>
                        <h4>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepGet.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepGet.svg'}
                                alt=''
                            />
                            Step 3
                        </h4>
                        <h3>Get answers + Deeper insights</h3>
                        <div className='ptpGetStartedContentImg'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepThree.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/stepThree.svg'}
                                alt=''
                            />
                        </div>
                        <p>
                            Analyze challenges, objectives and identify key improvement areas. Enact on the feedback
                            provided by your people and build a healthy workplace culture.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetStarted;
