import React from 'react';
import './style.scss';

const FastFunSimple = () => {
    return (
        <div className='fastFunSimpleWrap'>
            <div className='triviaContainer'>
                <div className='fastFunSimpleHeader'>
                    <h3>Fast, fun, and super-simple</h3>
                </div>

                <div className='fastFunSimple'>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/pre-built.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/pre-built.svg'}
                            alt=''
                        />
                        <h4>Get a head start with pre-built templates</h4>
                        <p>Choose from 45+ pre-defined templates and get to know your team better, instantly.</p>
                    </div>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/personlise.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/personlise.svg'}
                            alt=''
                        />
                        <h4>Personalize your Pulse for better results</h4>
                        <p>
                            Get accurate and honest answers, faster. Use ‘@’ mentions to directly notify your teammates
                            inside Slack and Microsoft Teams.
                        </p>
                    </div>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/build.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/build.svg'}
                            alt=''
                        />
                        <h4>Build a people first culture</h4>
                        <p>
                            Collect feedback, analyze insights, and enact on them. Make sure your people feel their
                            voices are heard.{' '}
                        </p>
                    </div>
                </div>

                <div className='fastFunSimple'>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/tenX.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/tenX.svg'}
                            alt=''
                        />
                        <h4>10x responses right inside Slack & Microsoft Teams</h4>
                        <p>
                            Unlike long, boring surveys, EngageWith Pulse gives you a quick yet exceptional experience
                            without disrupting the workflow. Meaning, your people can reply and get back to what they
                            were doing in no time.
                        </p>
                    </div>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/schedule.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/schedule.svg'}
                            alt=''
                        />
                        <h4>Auto-schedule your periodic Pulse</h4>
                        <p>
                            Keeping a tab on your workforce means only one thing - to check in frequently and
                            consistently. EngageWith automates periodic check-ins so you can ensure everyone in your
                            team is empowered to express their opinion individually.
                        </p>
                    </div>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/one-on-one/receive.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/one-on-one/receive.svg'}
                            alt=''
                        />
                        <h4>Instant answers minus the clutter</h4>
                        <p>
                            All the responses are captured and stored right inside Slack and Microsoft Teams. No more
                            cluttered spreadsheets or lengthy documents.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FastFunSimple;
